import type { AppContext } from '~~/types'
import { ref, computed, defineStore } from '#imports'

export const useAppContext = defineStore('appContext', () => {
  // state
  const context = ref<AppContext>('site')

  // computed
  const catalogPath = computed(() => {
    const pathes = {
      site: '/site/catalog',
      private: '/private/catalog',
      plug: '/',
    }
    return pathes[context.value]
  })

  const shopPath = computed(() => {
    const pathes = {
      site: '/site/shop',
      private: '/private/shop',
      plug: '/shop',
    }
    return pathes[context.value]
  })

  return {
    context,
    catalogPath,
    shopPath,
  }
})
